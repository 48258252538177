import React, {Suspense} from 'react'
import {CNavItem, CTabContent, CTabPane, CContainer, CSpinner} from '@coreui/react'
import {connect} from "react-redux";
import {Routes, Route, Navigate} from "react-router-dom";

import routes from '../../../routes'

const AppContent = () => {
	return (
	  <CContainer classNampaye="px-4" lg>
		<Suspense fallback={<CSpinner color="primary" />}>
		  <Routes>
			{routes.map((route, idx) => {
			  return (
				route.element && (
				  <Route
					key={idx}
					path={route.path}
					exact={route.exact}
					name={route.name}
					element={<route.element />}
				  />
				)
			  )
			})}
			<Route path="/" element={<Navigate to="dashboard" replace />} />
		  </Routes>
		</Suspense>
	  </CContainer>
	)
  }

const mapStateToProps = (state) => {
	const {footer} = state;
	return {
		footer
	};
}

export default connect(mapStateToProps, null)(AppContent);
